import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h2 className="heading-h2"><span className="bluecolor">Frequently </span> Asked Questions</h2>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is an Ethereum Token?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Ethereum tokens represent digital assets that can created for various purposes on an existing popular blockchain. These tokens are built under the guidelines of a specific token standard with the help of a smart contract and each token has different characteristics.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How does Ethereum token development differ from other blockchain platforms?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Ethereum is the most popular and the finest blockchain for developing crypto tokens. The platform differs from other blockchains due to its openness, programmability, and decentralized nature. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How do you Develop Ethereum Tokens?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">You can develop tokens on your own or approach a <span className="learn-more"><a href="https://www.coinsclone.com/crypto-token-development/">crypto token development services</a></span> providing company. While developing tokens on their own, there might be slight security risks associated. On the other hand, if you approach a token development company, the process is completely secure, reliable, and can be trusted completely.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How much does it cost to create Ethereum tokens?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The estimated cost to create a new Ethereum token varies from $5,000 to $7,000. However, for extra token features and requirements, the cost could vary subsequently and depend on the development company you choose.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                How long does it take to Make your own Ethereum Tokens?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">On average, you can create your own Ethereum token within a week if the requirement is simple and user-friendly. There might be a slight delay of two to three days for additional complex customizations required.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection