import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">Ethereum Token Development Services</h1>
            <p className="sub-heading">Create Ethereum-based tokens according to your preferred token standard, including popular standards like ERC20 and ERC721</p>
          </div>
          <div className="quick text-center d-none d-md-block">
          <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/ethereum-token-development.png"
              alt="Ethereum token development services Banner"
              width={948}
              height={565}
            />
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Create Ethereum Token</a>
        </div>
      </section>
    )
  }
}

export default BannerSection