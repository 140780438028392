import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container mw1140">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h4 bluecolor">Top Features </span> Of our Ethereum Token Development Services</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Token Compatibility</h3>
                <p className="pharagraph">Whether you’re investing in ERC20 or Bep20 token development, tokens must be designed to be compliant with ERC20 or Bep20 standards respectively.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/eth/token-compatibility.png"
              alt="Token Compatibility"
              width={536}
              height={313}
            />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/eth/token-cap.png"
              alt="Token Cap"
              width={579}
              height={313}
            />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Token Cap</h3>
                <p className="pharagraph">The maximum number of tokens that can be generated needs to be predefined. This ensures token buyers that the tokens are available in a limited quantity.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Token Supply</h3>
                <p className="pharagraph">It provides information about the total token supply and must be predefined at the time of generation.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/eth/token-supply.png"
              alt="Token Supply"
              width={572}
              height={313}
            />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/eth/token-minting.png"
              alt="Token Minting"
              width={562}
              height={313}
            />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Token Minting</h3>
                <p className="pharagraph">The token owner can define how the users can mint the tokens. They can also stop minting the tokens to increase the token value.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Token Burning</h3>
                <p className="pharagraph">The token owner can permanently remove the number of tokens from circulation</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/eth/token-burning.png"
              alt="Token Burning"
              width={536}
              height={313}
            />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures