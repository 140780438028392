import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'




const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>How Does</span> our </span>
            Ethereum Token Development Work?
          </h2>
        </div>
        { isDesktop && (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={935} height={1295} viewBox="0 0 935 1295">
          <defs>
            <linearGradient id="linear-gradient" x1="0.901" y1="0.74" x2="0.273" y2="0.369" gradientUnits="objectBoundingBox">
              <stop offset={0} />
              <stop offset={1} stopColor="#28282a" />
            </linearGradient>
          </defs>
          <g id="Flow" transform="translate(-428 -4394)">
            <g id="Line">
              <line id="Line_149" data-name="Line 149" y2="587.98" transform="translate(776 4540.52)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172" data-name="Line 172" y2={181} transform="translate(1299.5 4706.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172-2" data-name="Line 172" y2={133} transform="translate(1131.5 4892.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172-3" data-name="Line 172" x1={156} transform="translate(1131.5 5034.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172-4" data-name="Line 172" y2={142} transform="translate(1287.5 5034.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172-5" data-name="Line 172" y2={490} transform="translate(1240.5 5144.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172-6" data-name="Line 172" y2={290} transform="translate(782.5 5359.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <g id="Group_1" data-name="Group 1">
                <line id="Line_172-7" data-name="Line 172" y2={251} transform="translate(949 5152.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_172-8" data-name="Line 172" x1="66.5" transform="translate(882.5 5152.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_172-9" data-name="Line 172" x1="182.5" transform="translate(949 5403.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              </g>
              <g id="Group_2" data-name="Group 2" transform="translate(0 -441)">
                <line id="Line_172-10" data-name="Line 172" y2={578} transform="translate(988 5152.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_172-11" data-name="Line 172" x1={106} transform="translate(882.5 5152.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
                <line id="Line_172-12" data-name="Line 172" x1={143} transform="translate(988.5 5730.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              </g>
              <line id="Line_172-13" data-name="Line 172" x1={333} transform="translate(782.5 5649.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_173" data-name="Line 173" x2={36} transform="translate(1263.5 4887.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_169" data-name="Line 169" y1={129} transform="translate(716 5198)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            </g>
            <g id="Dots">
              <circle id="Ellipse_1271" data-name="Ellipse 1271" cx="7.5" cy="7.5" r="7.5" transform="translate(769 4533)" fill="#4b9aff" />
              <circle id="Ellipse_1272" data-name="Ellipse 1272" cx="7.5" cy="7.5" r="7.5" transform="translate(769 4750)" fill="#4b9aff" />
              <circle id="Ellipse_1273" data-name="Ellipse 1273" cx="7.5" cy="7.5" r="7.5" transform="translate(769 4985)" fill="#4b9aff" />
              <circle id="Ellipse_1274" data-name="Ellipse 1274" cx="7.5" cy="7.5" r="7.5" transform="translate(709 5189)" fill="#4b9aff" />
              <circle id="Ellipse_1277" data-name="Ellipse 1277" cx="7.5" cy="7.5" r="7.5" transform="translate(1292.25 4697.3)" fill="#ff6464" />
              <circle id="Ellipse_1277-2" data-name="Ellipse 1277" cx="7.5" cy="7.5" r="7.5" transform="translate(1123.25 4921.3)" fill="#ff6464" />
              <circle id="Ellipse_1277-3" data-name="Ellipse 1277" cx="7.5" cy="7.5" r="7.5" transform="translate(1252.25 5026.3)" fill="#ff6464" />
              <circle id="Ellipse_1277-4" data-name="Ellipse 1277" cx="7.5" cy="7.5" r="7.5" transform="translate(1233.25 5209.3)" fill="#ff6464" />
              <circle id="Ellipse_1277-5" data-name="Ellipse 1277" cx="7.5" cy="7.5" r="7.5" transform="translate(1233.25 5543.3)" fill="#ff6464" />
            </g>
            <g id="User_Login" data-name="User Login" transform="translate(5.078 4091.874)">
              <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(422.922 319.126)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <circle cx={108} cy={108} r={108} stroke="none" />
                <circle cx={108} cy={108} r={107} fill="none" />
              </g>
              <g id="User" transform="translate(-664.078 -11334.874)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={123} height={43} rx="21.5" transform="translate(1291 11760)" />
                <text id="User-2" data-name="User" transform="translate(1336.586 11786)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>User</tspan></text>
              </g>
              <text id="User_Can_be_Login_or_Register" data-name="User Can be Login  or Register" transform="translate(797.922 443.126)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x={0} y={0}>Signup / Login or</tspan><tspan x={0} y={24}>Register</tspan></text>
            </g>
            <g id="Admin" transform="translate(1043.05 4519.337)">
              <g id="Ellipse_1270" data-name="Ellipse 1270" transform="translate(78.95 20.663)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <circle cx={83} cy={83} r={83} stroke="none" />
                <circle cx={83} cy={83} r={82} fill="none" />
              </g>
              <g id="Admin-2" data-name="Admin" transform="translate(-1161.05 -11597.837)">
                <rect id="Rectangle_298-2" data-name="Rectangle 298" width={142} height={43} rx="21.5" transform="translate(1330 11719)" />
                <text id="Admin_Dashboard" data-name="Admin Dashboard" transform="translate(1379 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Admin</tspan></text>
              </g>
            </g>
            <g id="Admin_KYC" data-name="Admin KYC" transform="translate(-96)">
              <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(1110 4859)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={245} height={57} rx={15} stroke="none" />
                <rect x={1} y={1} width={243} height={55} rx={14} fill="none" />
              </g>
              <text id="User_KYC_Submission" data-name="User KYC Submission" transform="translate(1166 4892)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Whitepaper Drafting</tspan></text>
            </g>
            <g id="Admin_KYC-2" data-name="Admin KYC" transform="translate(-456 -203)">
              <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(1126 4890)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={213} height={57} rx={15} stroke="none" />
                <rect x={1} y={1} width={211} height={55} rx={14} fill="none" />
              </g>
              <text id="User_KYC_Submission-2" data-name="User KYC Submission" transform="translate(1185 4923)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Complete KYC</tspan></text>
            </g>
            <g id="Admin_KYC-3" data-name="Admin KYC" transform="translate(-456 26)">
              <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(1093 4890)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={277} height={57} rx={15} stroke="none" />
                <rect x={1} y={1} width={275} height={55} rx={14} fill="none" />
              </g>
              <text id="User_KYC_Submission-3" data-name="User KYC Submission" transform="translate(1138 4923)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Deposit Wallet Crypto / Fiat</tspan></text>
            </g>
            <g id="UserDashboard" transform="translate(-496 368)">
              <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(1176 4755)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={201} height={57} rx={15} stroke="none" />
                <rect x={1} y={1} width={199} height={55} rx={14} fill="none" />
              </g>
              <text id="User_Dashboard" data-name="User Dashboard" transform="translate(1239 4788)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Buy Tokens</tspan></text>
            </g>
            <g id="Wallet_Balance" data-name="Wallet Balance" transform="translate(-393 553)">
              <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(1056 4745)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={245} height={57} rx={15} stroke="none" />
                <rect x={1} y={1} width={243} height={55} rx={14} fill="none" />
              </g>
              <text id="User_Wallet_Balance" data-name="User Wallet Balance" transform="translate(1096 4778)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Withdraw / Store Tokens</tspan></text>
            </g>
            <g id="Admin_Reports" data-name="Admin  Reports" transform="translate(527.25 4413.3)">
              <g id="Group_23268" data-name="Group 23268" transform="translate(-684.25 -5283)">
                <g id="Rectangle_5486" data-name="Rectangle 5486" transform="translate(1187 5864.7)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={215} height={75} rx={15} stroke="none" />
                  <rect x={1} y={1} width={213} height={73} rx={14} fill="none" />
                </g>
                <text id="Currency_Settings" data-name="Currency Settings" transform="translate(1293 5895.7)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-57.127" y={0}>Ethereum Based </tspan><tspan x="-51.044" y={24}>Token Creation</tspan></text>
              </g>
              <g id="Group_23268-2" data-name="Group 23268" transform="translate(-576.25 -5148)">
                <g id="Rectangle_5486-2" data-name="Rectangle 5486" transform="translate(1187 5864.7)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={215} height={75} rx={15} stroke="none" />
                  <rect x={1} y={1} width={213} height={73} rx={14} fill="none" />
                </g>
                <text id="Currency_Settings-2" data-name="Currency Settings" transform="translate(1293 5895.7)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-66.171" y={0}>Integrate the tokens </tspan><tspan x="-62.265" y={24}>in</tspan><tspan y={24} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Web Dashboard</tspan></text>
              </g>
              <g id="Group_23268-3" data-name="Group 23268" transform="translate(-576.25 -5028)">
                <g id="Rectangle_5486-3" data-name="Rectangle 5486" transform="translate(1187 5875.7)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={215} height={53} rx={15} stroke="none" />
                  <rect x={1} y={1} width={213} height={51} rx={14} fill="none" />
                </g>
                <text id="Currency_Settings-3" data-name="Currency Settings" transform="translate(1295 5906.7)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-73.052" y={0}>Approve / Reject KYC</tspan></text>
              </g>
              <g id="Group_23268-4" data-name="Group 23268" transform="translate(-576.25 -4915)">
                <g id="Rectangle_5486-4" data-name="Rectangle 5486" transform="translate(1187 5875.7)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={215} height={53} rx={15} stroke="none" />
                  <rect x={1} y={1} width={213} height={51} rx={14} fill="none" />
                </g>
                <text id="Currency_Settings-4" data-name="Currency Settings" transform="translate(1295 5906.7)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-37.709" y={0}>Sell Tokens</tspan></text>
              </g>
              <g id="Group_23268-5" data-name="Group 23268" transform="translate(-576.25 -4802)">
                <g id="Rectangle_5486-5" data-name="Rectangle 5486" transform="translate(1187 5875.7)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={215} height={53} rx={15} stroke="none" />
                  <rect x={1} y={1} width={213} height={51} rx={14} fill="none" />
                </g>
                <text id="Currency_Settings-5" data-name="Currency Settings" transform="translate(1295 5906.7)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-57.89" y={0}>View Sale Report</tspan></text>
              </g>
              <g id="Group_23268-6" data-name="Group 23268" transform="translate(-576.25 -4693)">
                <g id="Rectangle_5486-6" data-name="Rectangle 5486" transform="translate(1171 5888.7)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={241} height={80} rx={15} stroke="none" />
                  <rect x={1} y={1} width={239} height={78} rx={14} fill="none" />
                </g>
                <text id="Currency_Settings-6" data-name="Currency Settings" transform="translate(1292 5922.7)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-64.547" y={0}>List the Token in any</tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-61.166" y={22}>Popular Exchange</tspan></tspan></text>
              </g>
            </g>
            <g id="Admin-3" data-name="Admin" transform="translate(1021.523 4511.841)">
              <path id="Path_4" data-name="Path 4" d="M654.513,440.237v69.907L642.819,529.2l-.89,1.446H595.791l-.932-90.412Z" transform="translate(-580.878 -329.492)" fill="#2f2e41" />
              <path id="Path_5" data-name="Path 5" d="M607.27,440.238v69.906L595.576,529.2l-.717-88.963Z" transform="translate(-533.635 -329.493)" opacity="0.1" />
              <path id="Path_8" data-name="Path 8" d="M63,402.433v4.194H313.731v-6.058l-23.3-12.117H83.506Z" transform="translate(-63 -294.95)" fill="#2f2e41" />
              <path id="Path_9" data-name="Path 9" d="M63,426.706V430.9H313.731v-6.058Z" transform="translate(-63 -319.223)" opacity="0.1" />
              <path id="Path_14" data-name="Path 14" d="M684.893,492.487H673.705l-4.66-3.262h20.97Z" transform="translate(-641.085 -362.168)" opacity="0.1" />
              <path id="Path_15" data-name="Path 15" d="M684.893,533.076H673.705l-4.66-3.262h20.97Z" transform="translate(-641.085 -389.242)" opacity="0.1" />
              <path id="Path_16" data-name="Path 16" d="M684.893,590.461H673.705l-4.66-3.262h20.97Z" transform="translate(-641.085 -427.519)" opacity="0.1" />
              <path id="Path_17" data-name="Path 17" d="M684.893,647.846H673.705l-4.66-3.263h20.97Z" transform="translate(-641.085 -465.796)" opacity="0.1" />
              <path id="Path_18" data-name="Path 18" d="M712.2,388.871l1.555,9.925s7.653,4.066,2.272,4.185-30.732,0-30.732,0-4.9,0,2.87-4.3l1.555-10.4Z" transform="translate(-617.803 -307.033)" fill="#3f3d56" />
              <path id="Path_19" data-name="Path 19" d="M717.11,402.874a21.172,21.172,0,0,0-3.354-2.221l-1.555-9.925-22.481.021-1.555,9.784a20.412,20.412,0,0,0-3.592,2.364c-1-.228-2.089-1.074,3.592-4.221l1.555-10.4,22.481.6,1.555,9.925S720.187,402.214,717.11,402.874Z" transform="translate(-617.808 -307.032)" opacity="0.1" />
              <rect id="Rectangle_1" data-name="Rectangle 1" width="101.284" height="70.552" rx="10.695" transform="translate(32.63 12.662)" fill="#3f3d56" />
              <rect id="Rectangle_2" data-name="Rectangle 2" width="94.468" height="52.855" transform="translate(36.038 16.428)" fill="#f2f2f2" />
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="0.598" cy="0.598" r="0.598" transform="translate(82.674 14.156)" fill="#f2f2f2" />
              <path id="Path_20" data-name="Path 20" d="M582.324,364.931v6.185a3.561,3.561,0,0,0,3.562,3.561h94.162a3.561,3.561,0,0,0,3.561-3.562v-6.183Z" transform="translate(-549.694 -291.462)" fill="#3f3d56" />
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="2.272" cy="2.272" r="2.272" transform="translate(81 76.099)" fill="#f2f2f2" />
              <path id="Path_21" data-name="Path 21" d="M362.071,406.573v1.2h64.334v-.957l-.088-.239-1.586-4.3H363.386Z" transform="translate(-275.258 -304.166)" fill="#3f3d56" />
              <path id="Path_22" data-name="Path 22" d="M743.912,440.074c.117.5.56,1.03,1.559,1.529,3.587,1.794,10.882-.478,10.882-.478s5.62-.957,5.62-3.471a4.473,4.473,0,0,0-.492-.293c-1.508-.8-6.508-2.767-15.376.083a3.7,3.7,0,0,0-1.686,1.1,1.764,1.764,0,0,0-.506,1.519Z" transform="translate(-681.857 -338.838)" fill="#3f3d56" />
              <path id="Path_23" data-name="Path 23" d="M745.391,440.074c4.391,1.683,8.3,1.808,12.322-.982a8.821,8.821,0,0,1,5.247-1.728c-1.508-.8-6.508-2.767-15.376.083a3.7,3.7,0,0,0-1.685,1.1,1.763,1.763,0,0,0-.506,1.519Z" transform="translate(-683.336 -338.839)" opacity="0.1" />
              <ellipse id="Ellipse_4" data-name="Ellipse 4" cx="1.555" cy="0.478" rx="1.555" ry="0.478" transform="translate(66.005 99.298)" fill="#f2f2f2" />
              <path id="Path_24" data-name="Path 24" d="M362.071,415.2v1.2h64.334v-.957l-.088-.239Z" transform="translate(-275.258 -312.79)" opacity="0.1" />
              <ellipse id="Ellipse_5" data-name="Ellipse 5" cx="12.735" cy="12.735" rx="12.735" ry="12.735" transform="translate(127.567 3.331)" fill="#ffb9b9" />
              <path id="Path_26" data-name="Path 26" d="M555.735,208.3v20.108H534.957s2.681-16.757,1.341-21.784Z" transform="translate(-406.048 -185.871)" fill="#ffb9b9" />
              <path id="Path_27" data-name="Path 27" d="M463.151,259.83s19.773-9.719,19.773-10.389,2.011-5.027,3.352-5.027,12.735-3.688,19.1,1.675l.67,4.357,24.465,16.086L523.812,291s-4.357,4.692-3.016,8.043-1.005,20.444-1.005,20.444l-52.616-1.675V295.019a53.136,53.136,0,0,0-2.681-7.038c-1.006-1.676,0-6.7,0-6.7l-8.043-10.051S457.454,261.84,463.151,259.83Z" transform="translate(-354.686 -210.25)" fill="#d0cde1" />
              <path id="Path_28" data-name="Path 28" d="M453.677,321.527l-1.508-8.211s8.714,37.871,7.708,42.23S451.5,366.6,450.493,366.94,440.1,338.119,440.1,338.119Z" transform="translate(-278.687 -257.034)" opacity="0.1" />
              <path id="Path_29" data-name="Path 29" d="M439.427,310.3l5.7,1.005s8.713,37.871,7.708,42.23-8.379,11.059-9.384,11.395-10.389-28.822-10.389-28.822Z" transform="translate(-269.296 -255.021)" fill="#d0cde1" />
              <path id="Path_30" data-name="Path 30" d="M641.724,332.937l-2.848-5.53s-4.022,37.871-1.341,41.557,13.286,5.066,13.286,5.066l.119-27.52Z" transform="translate(-535.102 -266.433)" opacity="0.1" />
              <path id="Path_31" data-name="Path 31" d="M649.272,314.323l-4.357,4.357s-4.022,37.871-1.341,41.557,13.286,5.066,13.286,5.066l.119-27.52Z" transform="translate(-543.154 -257.706)" fill="#d0cde1" />
              <path id="Path_32" data-name="Path 32" d="M504.32,485.505s9.719-3.688,11.395,3.352-4.022,38.542-4.022,38.542-3.688,23.795-6.032,24.465-12.735,1.005-14.411-2.011S496.277,516,496.277,516l-38.876-.67s2.346,26.811,3.688,29.157,1.341,10.389,0,10.389S445,554.209,444,552.2s-6.7-47.254-6.7-47.254-4.692-15.751.67-14.746S504.32,485.505,504.32,485.505Z" transform="translate(-333.184 -371.245)" fill="#3f3d56" />
              <path id="Path_33" data-name="Path 33" d="M609.518,681.617s-3.352-2.346-5.027,0-2.346,5.027,5.027,9.049,13.069,3.015,13.069,2.346v-9.719Z" transform="translate(-495.69 -502.004)" fill="#2f2e41" />
              <path id="Path_34" data-name="Path 34" d="M463.226,678.6s3.352-2.346,5.027,0,2.346,5.027-5.027,9.049-13.07,3.016-13.07,2.346v-9.721Z" transform="translate(-291.42 -499.991)" fill="#2f2e41" />
              <path id="Path_35" data-name="Path 35" d="M533.4,144.3a10.774,10.774,0,0,1,3.741.533,14.461,14.461,0,0,1,2.665,1.41l5.623,3.5a2.633,2.633,0,0,1,1.587,2.454l.7,5.261a6.849,6.849,0,0,1-.254,3.948,10.907,10.907,0,0,1-2.22,2.491,9.142,9.142,0,0,0-2.409,4.682c-.4,2.2-.145,4.845-1.88,6.244a5.907,5.907,0,0,1-3.432.973,51.207,51.207,0,0,1-9.89-.38,3.935,3.935,0,0,1-1.494-.419,4.1,4.1,0,0,1-1.311-1.559c-1.255-2.137-2.55-4.411-2.536-6.89.007-1.229.32-2.544-.268-3.619a14.131,14.131,0,0,0-1.012-1.314,7.257,7.257,0,0,1-.99-3.592c-.38-4,.017-7.042,2.965-9.858A15.244,15.244,0,0,1,533.4,144.3Z" transform="translate(-393.175 -144.291)" fill="#2f2e41" />
              <path id="Path_36" data-name="Path 36" d="M272.473,569.7l11.185,56.857h-5.593l-10.719-55.925Z" transform="translate(-100.97 -415.848)" fill="#2f2e41" />
              <path id="Path_37" data-name="Path 37" d="M420.359,579.965l-6.991,65.246h-4.66L412.9,578.1Z" transform="translate(-284.741 -421.451)" fill="#2f2e41" />
              <path id="Path_38" data-name="Path 38" d="M480.54,576.234l-9.787,43.342h-4.66l9.32-44.276Z" transform="translate(-364.029 -419.584)" fill="#2f2e41" />
              <path id="Path_39" data-name="Path 39" d="M355.05,578.1l1.4,34.487H352.72l-2.8-34.487Z" transform="translate(-201.257 -421.451)" fill="#2f2e41" />
              <path id="Path_40" data-name="Path 40" d="M519.158,484.212s7.457-49.866-13.515-51.265-50.8,0-50.8,0-17.243-1.4-12.117,51.265c0,0-4.66,15.379,37.283,13.981S519.158,484.212,519.158,484.212Z" transform="translate(-339.731 -336.416)" fill="#6c63ff" />
            </g>
            <g id="User_Login-2" data-name="User Login" transform="translate(107.769 4374.05)">
              <g id="Group_23234" data-name="Group 23234" transform="translate(342.237 94.756)">
                <g id="Group_23233" data-name="Group 23233">
                  <path id="Path_13507" data-name="Path 13507" d="M409.5,103.631,381,87.178a.331.331,0,0,0-.344-.018L342.42,109.41a.326.326,0,0,1,.344.02l28.5,16.453a2.594,2.594,0,0,0,2.617.245l38.236-22.249A2.585,2.585,0,0,1,409.5,103.631Z" transform="translate(-341.953 9.224)" fill="#bababa" />
                  <path id="Path_13508" data-name="Path 13508" d="M358.179,102.334l-.132,34.5c-.005,2.393-.851,4.074-2.212,4.867l38.236-22.252c1.361-.79,2.207-2.472,2.214-4.867l.133-34.5Z" transform="translate(-321.186 -1.666)" fill="#dbdbdb" />
                  <path id="Path_13509" data-name="Path 13509" d="M382.092,69.215l-38.237,22.25L369.209,106.1l38.237-22.25Z" transform="translate(-339.732 -18.493)" fill="#f4f4f4" />
                  <path id="Path_13510" data-name="Path 13510" d="M343.949,72.562l-.094,48.163,38.237-22.25.093-48.163Z" transform="translate(-339.732 -47.754)" fill="#dbdbdb" />
                  <path id="Path_13511" data-name="Path 13511" d="M384.3,51.544a2.045,2.045,0,0,0-.344-.5,6.2,6.2,0,0,0-1.5-1.175c-.736-.426-1.475-.675-1.779-.5L342.441,71.615c.306-.176,1.042.074,1.779.5a6.218,6.218,0,0,1,1.5,1.175,2.071,2.071,0,0,1,.341.5.791.791,0,0,1,.079.324l38.236-22.249a.788.788,0,0,0-.076-.321Z" transform="translate(-341.92 -49.309)" fill="#f4f4f4" />
                  <path id="Path_13512" data-name="Path 13512" d="M371.715,123.4,346.36,108.762l.094-48.163c0-.6-.958-1.45-1.919-2s-1.921-.81-1.921-.214l-.1,50.349a4.233,4.233,0,0,0,1.9,3.325L371.7,127.814a8.144,8.144,0,0,1,3.687,6.429l-.13,34.5c-.008,2.347-1.674,3.335-3.715,2.158l-28.5-16.453c-1.073-.622-1.085,3.791-.013,4.411l28.5,16.453c4.151,2.4,7.552.459,7.565-4.349l.133-34.5A16.623,16.623,0,0,0,371.715,123.4Z" transform="translate(-342.237 -35.791)" fill="#c6c6c6" />
                  <path id="Path_13513" data-name="Path 13513" d="M398.246,82.324a18.263,18.263,0,0,0-2.928-4.6,13.284,13.284,0,0,0-3.277-2.759L353.806,97.209a13.378,13.378,0,0,1,3.274,2.76,18.162,18.162,0,0,1,2.928,4.607,14.272,14.272,0,0,1,1.312,5.692l38.238-22.249a14.335,14.335,0,0,0-1.312-5.695Z" transform="translate(-324.327 -9.6)" fill="#f4f4f4" />
                </g>
                <path id="Path_13514" data-name="Path 13514" d="M393.329,76.043,355.091,98.292a18.163,18.163,0,0,1,2.928,4.607l38.238-22.252a18.263,18.263,0,0,0-2.928-4.6Z" transform="translate(-322.338 -7.924)" fill="#f4f4f4" />
              </g>
              <g id="Group_23240" data-name="Group 23240" transform="translate(345.473 19.95)">
                <g id="Group_23235" data-name="Group 23235" transform="translate(53.552 192.499)">
                  <path id="Path_13515" data-name="Path 13515" d="M380.52,126.075l-.772-.961c-4.347-8.48-4.724-18.384-4.676-27.913,0-.589.031-1.129.041-1.7a32.006,32.006,0,0,1-9.165.782c.884,3.5,1.738,7.086,1.182,10.223-.976,5.486-1.228,9.124.785,14.975a16.275,16.275,0,0,0,3.172,5.634,14.481,14.481,0,0,0,4.956,2.742,4.932,4.932,0,0,0,2.026.482,3.652,3.652,0,0,0,2.691-1.809C381.284,127.726,381.435,126.353,380.52,126.075Z" transform="translate(-362.32 -95.499)" fill="#f4bd98" />
                  <path id="Path_13516" data-name="Path 13516" d="M366.433,115.757l2.18-1.308v-6.6l-2.865-3.176Z" transform="translate(-362.629 -81.301)" fill="#3e5293" />
                  <path id="Path_13517" data-name="Path 13517" d="M366.022,121.812l2.306,1.457.059-9.285a5.389,5.389,0,0,1,1.149,2.844c.691,3.86,1.72,9.384,3.463,10.3,4.609,2.428,11.127,1.9,12.269.668a6.2,6.2,0,0,0-3.315-9.369c-2.444,1.99-5.328,2.433-7.718.382s-3.6-5.155-4.362-8.212a24.058,24.058,0,0,1-.538-10.781c-2.283,1.554-4.813,3.557-4.811,6.317a12.107,12.107,0,0,0,.64,3.025C366.236,113.215,366.022,121.812,366.022,121.812Z" transform="translate(-364.524 -88.813)" fill="#5e71ba" />
                </g>
                <g id="Group_23236" data-name="Group 23236" transform="translate(78.413 184.299)">
                  <path id="Path_13518" data-name="Path 13518" d="M391.21,113.8l-.861-.882c-3.934-6.146-5.626-13.344-6.594-20.641-.025.008-.046.02-.074.028q-1.5.432-3,.859l-.008-.053c-.543.13-1.108.25-1.735.357a6,6,0,0,1-1.169.076,16.088,16.088,0,0,1-1.888.061,19.08,19.08,0,0,1,.127,1.993c0,6.187.168,9.981,2.734,15.612a11.945,11.945,0,0,0,3.177,4.525,14.536,14.536,0,0,0,5.2,2.255,4.944,4.944,0,0,0,2.061.285,3.64,3.64,0,0,0,2.5-2.059C392.127,115.374,392.148,113.99,391.21,113.8Z" transform="translate(-371.804 -92.281)" fill="#f4bd98" />
                  <path id="Path_13519" data-name="Path 13519" d="M377.478,109.448l2.045-1.51-.632-6.571-3.156-2.888Z" transform="translate(-372.03 -82.687)" fill="#3e5293" />
                  <path id="Path_13520" data-name="Path 13520" d="M377.3,115.8l2.433,1.231-.831-9.247a5.389,5.389,0,0,1,1.417,2.719c1.055,3.776,2.609,9.178,4.431,9.924,4.821,1.977,11.26.826,12.276-.51a6.191,6.191,0,0,0-4.2-9.007c-2.24,2.214-5.068,2.93-7.644,1.119s-4.077-4.79-5.129-7.759a24.091,24.091,0,0,1-1.567-10.681c-2.123,1.768-4.449,4-4.184,6.752a12.238,12.238,0,0,0,.925,2.945C376.684,107.218,377.3,115.8,377.3,115.8Z" transform="translate(-374.281 -90.264)" fill="#5e71ba" />
                </g>
                <path id="Path_13521" data-name="Path 13521" d="M403.047,106.957a32.826,32.826,0,0,0,2.365,9.542c2.28,5.583,5.216,12.7,5.216,18.728.583,1.312,7.254,1.243,8.691-.637a93.372,93.372,0,0,1-1.269-9.4,153.809,153.809,0,0,1-.372-20.275,48.985,48.985,0,0,0-.324-10.314c-.507-3.047-2.732-8.566-6.834-11.726-2.186-1.684-31.32-15.352-38.941-16.019-4.772-.418-14.822-.538-14.475,6.245.138,2.714-4.986,6.564-3.616,8.918.856,1.468,8.2,4.821,15.872,8.166,5.361,2.337,22.193,7.4,24.163,7.947C400.57,100.106,402.682,102.837,403.047,106.957Z" transform="translate(-328.417 52.41)" fill="#28282a" />
                <path id="Path_13522" data-name="Path 13522" d="M390,117.306a32.777,32.777,0,0,0,1.442,9.726c1.735,5.776,3.975,13.138,3.4,19.141.456,1.363,7.1,1.931,8.712.2a93.908,93.908,0,0,1-.362-9.479,153.968,153.968,0,0,1,1.567-20.219c.533-4.067.059-17.808-5.012-22.624-2-1.9-29.478-17.072-37-18.465-4.711-.874-9.209-9.155-13.752-4.1-1.9,2.1-1.264,11.723-.125,14.2.711,1.544,1.891,5.025,9.2,9.089,5.111,2.839,21.4,12.065,23.289,12.847C388.632,110.628,390.031,113.171,390,117.306Z" transform="translate(-336.884 57.308)" fill="#28282a" />
                <path id="Path_13523" data-name="Path 13523" d="M377.048,48.714l-3.32-8.977-12.317,2.984-3.014,4.5,1.865,7.145s.907,2.382,10.218-1.014C377.3,50.862,377.048,48.714,377.048,48.714Z" transform="translate(-320.456 10.68)" fill="#4a5d99" />
                <path id="Path_13524" data-name="Path 13524" d="M419.284,51.953c-.594-1.712-8.9-2.785-14.131,4.6-1.8,2.54-2.247,4.324-2.851,7.31C399.16,65.42,382.67,74.544,382.67,74.544s-10.5-37.28-18.534-37.265c-16.529.031,6.108,43.1,11.425,50.142a1.4,1.4,0,0,0,1.852.354c3.842-2.306,17.946-10.918,28.839-19.536,2.436-1.226,6.1-6.961,8.5-11.3C414.748,56.937,420.3,54.883,419.284,51.953Z" transform="translate(-320.774 6.874)" fill="#f9cfb2" />
                <path id="Path_13525" data-name="Path 13525" d="M366.421,62.038C361.465,51.285,356.9,46.3,358.38,39.86c.843-3.684,3.847-5.891,8.76-2.831a17.486,17.486,0,0,1,4.938,4.964c3.111,4.762,5.417,13.076,5.417,13.076C375.5,53.917,369.988,60.2,366.421,62.038Z" transform="translate(-320.92 4.25)" fill="#5e71ba" />
                <g id="Group_23237" data-name="Group 23237">
                  <path id="Path_13526" data-name="Path 13526" d="M370.636,32.921a1.965,1.965,0,0,0,.2-2.907c-.583-.7-1.516-.716-2.26-1.236-1.19-.833-2.415-2.171-3.684-2.879a4.89,4.89,0,0,0-2.11-3.682c-7.937-6.681-23.827,1.809-18.025,20.173,1.677,5.3,6.793,13.8,6.283,25.536,3.825-2.331,6.948-10.842,7.394-14.852.459-4.107-.224-7.647-.041-11.777C358.508,38.629,369.571,34.088,370.636,32.921Z" transform="translate(-343.507 -19.95)" fill="#54361f" />
                  <path id="Path_13527" data-name="Path 13527" d="M362.1,28.294a12.715,12.715,0,0,0-2.222-2.25c-1.182-1.057-2.515-2.143-3.83-3.31-2.571-2.326-7.8,3.8-5.967,5.891.283.354,3.5,2.642,3.5,2.642,1.019,1.478,6.627,1.284,7.932.155a2.148,2.148,0,0,0,.587-3.128Z" transform="translate(-333.924 -16.444)" fill="#5e71ba" />
                </g>
                <g id="Group_23239" data-name="Group 23239" transform="translate(7.739 7.843)">
                  <g id="_2" data-name={2} transform="translate(0 10.866)">
                    <path id="Path_13528" data-name="Path 13528" d="M393.784,62.875c.555-2.275-1.086-5.453-2.861-8.064a66.184,66.184,0,0,1-6.38-11.509c-2.153-5.114-4.069-8.273-6.148-7.6-.772.25-25.6,13.581-26.617,14.085a10.723,10.723,0,0,0-5.022,6.011c-1.144,2.912,2.637,14.422,4.663,33.394a120.569,120.569,0,0,1-2.1,36.1,1.923,1.923,0,0,0,.976,2.143c8.031,4.084,35.073,3.391,38.865-11.018a100.747,100.747,0,0,1-2.145-16.139C385.753,82.013,391.674,71.533,393.784,62.875Z" transform="translate(-346.544 -14.415)" fill="#5e71ba" />
                    <path id="Path_13529" data-name="Path 13529" d="M372.973,44.751c-1.348-4.579-1.832-7.038-3.947-8a3.458,3.458,0,0,0-1.037-.255c-4.744,2.5-12.406,6.594-17.439,9.28l-.005.028a1.422,1.422,0,0,0,.354.624,52.931,52.931,0,0,0,10.1,8.76C366.006,58.064,377.22,59.191,372.973,44.751Z" transform="translate(-340.351 -13.044)" fill="#f9cfb2" />
                    <g id="Group_23238" data-name="Group 23238" transform="translate(10.829)">
                      <path id="Path_13530" data-name="Path 13530" d="M351.416,35.6c.874-1.236,14.022-3.56,16.988,7.055s-2.558,12.327-2.558,12.327c-4.941,1.98-11.02-3.608-13.489-6.273a1.378,1.378,0,0,1-.39-.922C351.834,45.411,351.306,35.757,351.416,35.6Z" transform="translate(-349.854 -16.148)" fill="#f2c3a0" />
                      <path id="Path_13531" data-name="Path 13531" d="M371.706,53.914c-.6,2.209-1.266,4.885-3.4,5.55-2.568.8-9.308-2.321-12.544-6.441-3.776-4.808-5.883-12-4.579-15.393.973-2.528,5.364-4.513,7.675-6.288a21.084,21.084,0,0,1,5.407-2.81c5.453-2.1,10.246-1.9,10.4,3.58a72.3,72.3,0,0,1-.125,8.408C373.741,49.292,372.577,50.711,371.706,53.914Z" transform="translate(-350.794 -27.293)" fill="#f9cfb2" />
                    </g>
                  </g>
                  <path id="Path_13532" data-name="Path 13532" d="M379.812,40.782c.647-6.64-.316-13.561-8.732-16.715-7.782-2.915-17.332.54-21.342,7.476a12.7,12.7,0,0,0-1.524,6.813c.558,15.031,6.648,12.169,8.551,23.533,0-2.637.288-5.267.232-7.9-.074-3.539-2.7-9.163.951-8.543,2.061.349,2.222,4.742,2.257,6.8.023,1.271,1.508,1.779,1.865.915,1.139-2.757-.6-6.561,1.577-8.918,4.586-4.969,10.719-6.811,13.624-6.136a1.421,1.421,0,0,1,1.083,1.343,79.553,79.553,0,0,1,.054,8.737A32.8,32.8,0,0,0,379.812,40.782Z" transform="translate(-343.975 -23.029)" fill="#54361f" />
                </g>
                <path id="Path_13533" data-name="Path 13533" d="M364.725,55.437,362.3,46.178l-12.551,1.753-3.44,4.179,1.149,7.292s.67,2.461,10.268,0C364.763,57.6,364.725,55.437,364.725,55.437Z" transform="translate(-339.162 20.65)" fill="#4a5d99" />
                <path id="Path_13534" data-name="Path 13534" d="M362.539,60.444c-.861-3.5-5.114-17.584-9.127-18.641-1.193-.313-2.775-.4-3.577.54-11.522,13.4,9.66,46.774,12.366,48.7l6.635-8.7S364.445,68.162,362.539,60.444Z" transform="translate(-338.984 13.545)" fill="#f9cfb2" />
                <path id="Path_13535" data-name="Path 13535" d="M365.385,62.979c-2.145-11.6-3.363-17.846-8.465-20.884-3.5-2.087-9.835-.4-10.982,9.56-.29,2.525.673,9.889,2.184,15.288C349.516,61.536,362.684,58.8,365.385,62.979Z" transform="translate(-339.822 13.109)" fill="#5e71ba" />
              </g>
              <g id="Group_23241" data-name="Group 23241" transform="translate(357.385 104.546)">
                <path id="Path_13536" data-name="Path 13536" d="M443.229,167.814l-55.68-32.274-.22-77.654,55.68,32.274Z" transform="translate(-287.582 -45.822)" fill="#c6c6c6" />
                <path id="Path_13537" data-name="Path 13537" d="M404.1,195.005l-55.68-32.274-.241-85.217,55.68,32.274Z" transform="translate(-348.182 -15.437)" fill="#c6c6c6" />
                <path id="Path_13538" data-name="Path 13538" d="M403.862,147.5l-55.68-32.274L455.7,53.151l55.68,32.274Z" transform="translate(-348.182 -53.151)" fill="#f4f4f4" />
                <path id="Path_13539" data-name="Path 13539" d="M477.555,65.817l.242,85.217-7.8,4.5-.22-77.654L377.87,130.946l.22,77.654-7.815,4.512-.241-85.217Z" transform="translate(-314.354 -33.543)" fill="#dbdbdb" />
              </g>
              <g id="Group_23246" data-name="Group 23246" transform="translate(375.774 152.708)">
                <g id="Group_23245" data-name="Group 23245">
                  <path id="Path_13540" data-name="Path 13540" d="M363.684,72.3l28.255,16.42c.762.443.629,3.753-.413,4.355L363.269,76.656C364.311,76.054,364.444,72.742,363.684,72.3Z" transform="translate(-343.216 -71.668)" fill="#41568c" />
                  <path id="Path_13541" data-name="Path 13541" d="M375.443,74.01,403.7,90.431,383.661,102,355.4,85.579Z" transform="translate(-355.391 -69.023)" fill="#5e71ba" />
                  <path id="Path_13542" data-name="Path 13542" d="M383.661,94.972,355.4,78.55v.389L383.662,95.36Z" transform="translate(-355.391 -61.994)" fill="#41568c" />
                  <g id="Group_23242" data-name="Group 23242" transform="translate(1.324 12.085)">
                    <path id="Path_13543" data-name="Path 13543" d="M385.714,78.732c1.164-.673,1.175,3.5.01,4.174l-19.1,11.025-.01-4.176Z" transform="translate(-339.354 -73.911)" fill="#fffffa" />
                    <path id="Path_13544" data-name="Path 13544" d="M383.183,92.64l.012,4.176L355.93,80.972l-.012-4.176Z" transform="translate(-355.919 -76.796)" fill="#d6d6c9" />
                  </g>
                  <g id="Group_23243" data-name="Group 23243" transform="translate(28.258 16.422)">
                    <path id="Path_13545" data-name="Path 13545" d="M366.489,90.337v.39l.25-.145,19.79-11.425c1.2-.7,1.218,3.631.013,4.327L366.751,94.91l-.25.143v.39l20.392-11.772c1.228-.711,1.213-5.815-.015-5.106Z" transform="translate(-366.489 -78.498)" fill="#4f63a3" />
                  </g>
                  <path id="Path_13546" data-name="Path 13546" d="M375.788,72.12a.373.373,0,0,1,.392-.02l28.255,16.422a.363.363,0,0,0-.39.02l-20.389,11.772L355.4,83.891Z" transform="translate(-355.399 -72.053)" fill="#5e71ba" />
                  <g id="Group_23244" data-name="Group 23244" transform="translate(0 11.84)">
                    <path id="Path_13547" data-name="Path 13547" d="M383.656,93.12,355.4,76.7v.389L383.657,93.51Z" transform="translate(-355.399 -76.699)" fill="#41568c" />
                  </g>
                </g>
                <path id="Path_13548" data-name="Path 13548" d="M366.539,82.554l7.233-4.176.643.371-7.233,4.176Z" transform="translate(-338.153 -62.261)" fill="#f9e6d2" />
                <path id="Path_13549" data-name="Path 13549" d="M371.8,80.782l3.957-2.285.352.2-3.957,2.285Z" transform="translate(-330.007 -62.076)" fill="#f9e6d2" />
                <path id="Path_13550" data-name="Path 13550" d="M364.853,84.016,376.3,77.4l.722.417-11.452,6.612Z" transform="translate(-340.764 -63.769)" fill="#f9e6d2" />
                <path id="Path_13551" data-name="Path 13551" d="M364.028,83.54l11.452-6.612.722.417L364.75,83.957Z" transform="translate(-342.04 -64.506)" fill="#f9e6d2" />
                <path id="Path_13552" data-name="Path 13552" d="M376.882,75.987l-14.534,8.391,10.144,5.858,14.531-8.391ZM372.474,89.8,363.1,84.388l13.8-7.963,9.369,5.409Z" transform="translate(-344.642 -65.962)" fill="#f9e6d2" />
              </g>
              <g id="Group_23262" data-name="Group 23262" transform="translate(452.411 99.024)">
                <g id="Group_23251" data-name="Group 23251" transform="translate(0 13.679)">
                  <g id="Group_23250" data-name="Group 23250">
                    <path id="Path_13553" data-name="Path 13553" d="M393.761,56.6l28.258,16.419c.759.443.627,3.753-.415,4.357L393.346,60.955C394.388,60.353,394.521,57.041,393.761,56.6Z" transform="translate(-373.293 -55.968)" fill="#b88c33" />
                    <path id="Path_13554" data-name="Path 13554" d="M405.521,58.309,433.778,74.73,413.739,86.3,385.482,69.879Z" transform="translate(-385.467 -53.323)" fill="#e6b74a" />
                    <path id="Path_13555" data-name="Path 13555" d="M413.739,79.271,385.482,62.85v.389L413.74,79.66Z" transform="translate(-385.467 -46.294)" fill="#b88c33" />
                    <g id="Group_23247" data-name="Group 23247" transform="translate(1.325 12.085)">
                      <path id="Path_13556" data-name="Path 13556" d="M415.791,63.031c1.164-.673,1.175,3.5.013,4.176L396.709,78.23l-.013-4.176Z" transform="translate(-369.432 -58.21)" fill="#fffffa" />
                      <path id="Path_13557" data-name="Path 13557" d="M413.26,76.939l.012,4.176L386.008,65.271,386,61.1Z" transform="translate(-385.996 -61.095)" fill="#d6d6c9" />
                    </g>
                    <g id="Group_23248" data-name="Group 23248" transform="translate(28.257 16.421)">
                      <path id="Path_13558" data-name="Path 13558" d="M396.566,74.636v.39l.252-.145,19.788-11.425c1.208-.7,1.22,3.631.013,4.329L396.829,79.21l-.25.143v.39L416.97,67.97c1.228-.711,1.213-5.815-.015-5.106Z" transform="translate(-396.566 -62.797)" fill="#cfa03e" />
                    </g>
                    <path id="Path_13559" data-name="Path 13559" d="M405.865,56.418a.379.379,0,0,1,.392-.02L434.515,72.82a.373.373,0,0,0-.392.02l-20.39,11.772L385.476,68.19Z" transform="translate(-385.476 -56.352)" fill="#e6b74a" />
                    <g id="Group_23249" data-name="Group 23249" transform="translate(0.001 11.839)">
                      <path id="Path_13560" data-name="Path 13560" d="M413.733,77.42,385.476,61v.389L413.733,77.81Z" transform="translate(-385.476 -60.998)" fill="#b88c33" />
                    </g>
                  </g>
                  <path id="Path_13561" data-name="Path 13561" d="M396.617,66.853l7.233-4.176.643.371-7.234,4.176Z" transform="translate(-368.23 -46.561)" fill="#f9e6d2" />
                  <path id="Path_13562" data-name="Path 13562" d="M401.879,65.081l3.957-2.285.352.2-3.957,2.285Z" transform="translate(-360.083 -46.376)" fill="#f9e6d2" />
                  <path id="Path_13563" data-name="Path 13563" d="M394.93,68.315,406.382,61.7l.722.417-11.452,6.612Z" transform="translate(-370.841 -48.069)" fill="#f9e6d2" />
                  <path id="Path_13564" data-name="Path 13564" d="M394.106,67.839l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-372.117 -48.805)" fill="#f9e6d2" />
                  <path id="Path_13565" data-name="Path 13565" d="M406.957,60.287l-14.531,8.391,10.141,5.855L417.1,66.142ZM402.549,74.1l-9.369-5.412,13.8-7.963,9.369,5.409Z" transform="translate(-374.717 -50.261)" fill="#f9e6d2" />
                </g>
                <g id="Group_23256" data-name="Group 23256" transform="translate(0.145 6.828)">
                  <g id="Group_23255" data-name="Group 23255">
                    <path id="Path_13566" data-name="Path 13566" d="M393.818,53.911l28.258,16.422c.759.441.627,3.753-.415,4.355L393.4,58.266C394.445,57.664,394.578,54.354,393.818,53.911Z" transform="translate(-373.35 -53.28)" fill="#64398f" />
                    <path id="Path_13567" data-name="Path 13567" d="M405.578,55.62l28.257,16.421L413.8,83.611,385.539,67.19Z" transform="translate(-385.524 -50.634)" fill="#8f53bd" />
                    <path id="Path_13568" data-name="Path 13568" d="M413.8,76.582,385.539,60.161v.389L413.8,76.971Z" transform="translate(-385.524 -43.605)" fill="#64398f" />
                    <g id="Group_23252" data-name="Group 23252" transform="translate(1.325 12.085)">
                      <path id="Path_13569" data-name="Path 13569" d="M415.848,60.342c1.164-.673,1.175,3.5.013,4.176l-19.1,11.023-.013-4.176Z" transform="translate(-369.489 -55.522)" fill="#fffffa" />
                      <path id="Path_13570" data-name="Path 13570" d="M413.317,74.25l.012,4.176L386.065,62.582l-.012-4.176Z" transform="translate(-386.053 -58.406)" fill="#d6d6c9" />
                    </g>
                    <g id="Group_23253" data-name="Group 23253" transform="translate(28.257 16.422)">
                      <path id="Path_13571" data-name="Path 13571" d="M396.623,71.948v.387l.252-.145,19.788-11.425c1.208-.7,1.221,3.633.013,4.329l-19.79,11.425-.25.145v.387L417.028,65.28c1.228-.708,1.213-5.815-.015-5.1Z" transform="translate(-396.623 -60.108)" fill="#7946a6" />
                    </g>
                    <path id="Path_13572" data-name="Path 13572" d="M405.922,53.731a.367.367,0,0,1,.392-.02L434.572,70.13a.368.368,0,0,0-.392.023L413.79,81.925,385.533,65.5Z" transform="translate(-385.533 -53.663)" fill="#8f53bd" />
                    <g id="Group_23254" data-name="Group 23254" transform="translate(0.001 11.839)">
                      <path id="Path_13573" data-name="Path 13573" d="M413.79,74.731,385.533,58.31v.39l28.258,16.42Z" transform="translate(-385.533 -58.31)" fill="#64398f" />
                    </g>
                  </g>
                  <path id="Path_13574" data-name="Path 13574" d="M396.674,64.165l7.233-4.176.643.371-7.233,4.176Z" transform="translate(-368.287 -43.872)" fill="#f9e6d2" />
                  <path id="Path_13575" data-name="Path 13575" d="M401.936,62.393l3.957-2.285.352.2L402.288,62.6Z" transform="translate(-360.14 -43.687)" fill="#f9e6d2" />
                  <path id="Path_13576" data-name="Path 13576" d="M394.987,65.626l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-370.898 -45.38)" fill="#f9e6d2" />
                  <path id="Path_13577" data-name="Path 13577" d="M394.163,65.151l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-372.174 -46.117)" fill="#f9e6d2" />
                  <path id="Path_13578" data-name="Path 13578" d="M407.014,57.6l-14.531,8.391,10.141,5.855,14.534-8.391Zm-4.408,13.81L393.237,66l13.8-7.965,9.369,5.409Z" transform="translate(-374.774 -47.573)" fill="#f9e6d2" />
                </g>
                <g id="Group_23261" data-name="Group 23261" transform="translate(0.19)">
                  <g id="Group_23260" data-name="Group 23260">
                    <path id="Path_13579" data-name="Path 13579" d="M393.835,51.232l28.257,16.419c.759.443.627,3.753-.415,4.355L393.42,55.587C394.465,54.983,394.6,51.673,393.835,51.232Z" transform="translate(-373.369 -50.599)" fill="#ab3a3a" />
                    <path id="Path_13580" data-name="Path 13580" d="M405.6,52.941l28.257,16.421-20.039,11.57L385.556,64.51Z" transform="translate(-385.543 -47.954)" fill="#da5353" />
                    <path id="Path_13581" data-name="Path 13581" d="M413.813,73.9,385.556,57.481v.389l28.257,16.421Z" transform="translate(-385.543 -40.925)" fill="#ab3a3a" />
                    <g id="Group_23257" data-name="Group 23257" transform="translate(1.324 12.085)">
                      <path id="Path_13582" data-name="Path 13582" d="M415.865,57.662c1.164-.67,1.177,3.5.013,4.176l-19.1,11.026-.013-4.176Z" transform="translate(-369.507 -52.843)" fill="#fffffa" />
                      <path id="Path_13583" data-name="Path 13583" d="M413.334,71.571l.012,4.176L386.082,59.9l-.012-4.176Z" transform="translate(-386.07 -55.727)" fill="#d6d6c9" />
                    </g>
                    <g id="Group_23258" data-name="Group 23258" transform="translate(28.256 16.422)">
                      <path id="Path_13584" data-name="Path 13584" d="M396.64,69.268v.387l.25-.143,19.79-11.425c1.208-.7,1.221,3.631.013,4.327L396.9,73.839l-.25.145v.39L417.044,62.6c1.228-.708,1.213-5.812-.013-5.1Z" transform="translate(-396.64 -57.429)" fill="#c44343" />
                    </g>
                    <path id="Path_13585" data-name="Path 13585" d="M405.94,51.051a.368.368,0,0,1,.39-.02l28.258,16.422a.363.363,0,0,0-.39.02L413.806,79.244,385.551,62.822Z" transform="translate(-385.55 -50.984)" fill="#da5353" />
                    <g id="Group_23259" data-name="Group 23259" transform="translate(0 11.839)">
                      <path id="Path_13586" data-name="Path 13586" d="M413.807,72.051,385.551,55.63v.389L413.809,72.44Z" transform="translate(-385.551 -55.63)" fill="#ab3a3a" />
                    </g>
                  </g>
                  <path id="Path_13587" data-name="Path 13587" d="M396.691,61.485l7.233-4.176.643.371-7.233,4.176Z" transform="translate(-368.305 -41.193)" fill="#f9e6d2" />
                  <path id="Path_13588" data-name="Path 13588" d="M401.954,59.713l3.957-2.285.352.2-3.957,2.285Z" transform="translate(-360.158 -41.007)" fill="#f9e6d2" />
                  <path id="Path_13589" data-name="Path 13589" d="M395,62.947l11.452-6.612.722.417-11.452,6.612Z" transform="translate(-370.916 -42.7)" fill="#f9e6d2" />
                  <path id="Path_13590" data-name="Path 13590" d="M394.18,62.471l11.452-6.612.722.417L394.9,62.888Z" transform="translate(-372.192 -43.437)" fill="#f9e6d2" />
                  <path id="Path_13591" data-name="Path 13591" d="M407.031,54.918,392.5,63.309l10.141,5.858,14.534-8.391Zm-4.406,13.81-9.372-5.409,13.8-7.963,9.369,5.409Z" transform="translate(-374.793 -44.893)" fill="#f9e6d2" />
                </g>
              </g>
              <g id="Group_23266" data-name="Group 23266" transform="translate(392.74 122.802)">
                <g id="Group_23263" data-name="Group 23263" transform="translate(16.96 3.666)">
                  <path id="Path_13592" data-name="Path 13592" d="M403.283,65.089,369.1,84.952l10.2,5.89,34.18-19.863Z" transform="translate(-368.11 -56.593)" fill="#403d3b" />
                  <path id="Path_13593" data-name="Path 13593" d="M374.233,82.121l-5.13,7.213,34.18-19.863,5.13-7.213Z" transform="translate(-368.11 -60.975)" fill="#212020" />
                  <path id="Path_13594" data-name="Path 13594" d="M380.815,84.282l-5.389-13.91a1.452,1.452,0,0,0-.586-.731.429.429,0,0,0-.591.051L368.8,77.344a.892.892,0,0,0,0,.775,1.445,1.445,0,0,0,.589.731l10.837,6.258c.24.138.464.125.589-.054a.887.887,0,0,0,0-.772Zm-11.109-6.235,5.129-7.213,5.073,13.1Z" transform="translate(-368.713 -49.687)" fill="#282726" />
                  <path id="Path_13595" data-name="Path 13595" d="M411.566,76.486l-5.387-13.91-.033-.076a1.505,1.505,0,0,0-.255-.4,1.2,1.2,0,0,0-.3-.252.479.479,0,0,0-.484-.046L370.928,81.666a.484.484,0,0,1,.484.043,1.148,1.148,0,0,1,.3.252,1.6,1.6,0,0,1,.257.4l.031.079,5.389,13.91a.887.887,0,0,1,0,.772.368.368,0,0,1-.107.1L411.457,77.36a.329.329,0,0,0,.107-.1.892.892,0,0,0,0-.774Z" transform="translate(-365.285 -61.755)" fill="#383534" />
                </g>
                <g id="Group_23265" data-name="Group 23265">
                  <path id="Path_13596" data-name="Path 13596" d="M390.287,60.467l-1.264,1.343.385,1.111c.938-.545,1.371-1.035,1.264-1.343Z" transform="translate(-320.314 -60.082)" fill="#e0e0e0" />
                  <path id="Path_13597" data-name="Path 13597" d="M385.8,70.888s-23.666-.176-23.737-.387l.385,1.111c.071.211.4.336.973.336L386.183,72Z" transform="translate(-362.047 -44.549)" fill="#a0a0a0" />
                  <path id="Path_13598" data-name="Path 13598" d="M413.012,60.994,373.527,83.94l.384,1.111L413.4,62.1Z" transform="translate(-344.302 -59.267)" fill="#d9d9d9" />
                  <path id="Path_13599" data-name="Path 13599" d="M376.851,70l-5.471,1.666.382,1.111a13.05,13.05,0,0,0,5.473-1.666Z" transform="translate(-347.626 -45.327)" fill="#e0e0e0" />
                  <path id="Path_13600" data-name="Path 13600" d="M431.049,60.364l-22.761-.048a13.1,13.1,0,0,0-5.471,1.666L363.331,84.927c-1.58.92-1.707,1.674-.283,1.679l22.764.048a13.088,13.088,0,0,0,5.471-1.666l39.484-22.945C432.346,61.124,432.489,60.37,431.049,60.364Z" transform="translate(-362.057 -60.316)" fill="#b7b7b7" />
                  <path id="Path_13601" data-name="Path 13601" d="M428.427,60.665,405.6,60.614a8.646,8.646,0,0,0-4.367,1.172l-37.8,21.956a.243.243,0,0,0,.12.454L388,84.249a4.188,4.188,0,0,0,1.939-.52l38.653-22.448a.331.331,0,0,0-.165-.616Z" transform="translate(-360.123 -59.855)" fill="#191919" />
                  <path id="Path_13602" data-name="Path 13602" d="M411.619,60.822l-21.872-.046c-5.165,11.053-16.5,22.611-16.5,22.611a3.655,3.655,0,0,0,1.5-.464l37.035-21.51a.318.318,0,0,0-.163-.591Z" transform="translate(-344.737 -59.604)" opacity="0.5" fill="url(#linear-gradient)" />
                  <g id="Group_23264" data-name="Group 23264" transform="translate(12.051 24.978)">
                    <path id="Path_13603" data-name="Path 13603" d="M370.479,70.127l-2.535-.008a.71.71,0,0,0-.359.1l-.752.436a.091.091,0,0,0,.046.171h2.563a.457.457,0,0,0,.222-.059l.859-.492C370.612,70.223,370.622,70.119,370.479,70.127Z" transform="translate(-366.787 -70.119)" fill="#d9d9d9" />
                    <path id="Path_13604" data-name="Path 13604" d="M366.877,70.8l.749-.436a.726.726,0,0,1,.362-.1l2.53,0h0c.089-.046.1-.15-.043-.143l-2.535-.008a.71.71,0,0,0-.359.1l-.752.436a.089.089,0,0,0,.038.166Z" transform="translate(-366.785 -70.119)" fill="#a0a0a0" />
                  </g>
                </g>
              </g>
              <path id="Path_13605" data-name="Path 13605" d="M400.056,78.456c-.617-2.574-2.844-6.778-3.858-7.17-2.423-.933-8.86-1.643-12.633-1.957L359.55,57.608a6.355,6.355,0,0,0-6.635,8.7c.958.678,11.2,3.506,27.985,8.467,1.582.6,6.339,2.084,10.607,3.73,0,0,2.838,4.064,6.115,2.831C398.26,81.1,400.428,80,400.056,78.456Z" transform="translate(15.773 58.226)" fill="#f9cfb2" />
            </g>
          </g>
        </svg>
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/ethereum-token-mobile.webp" 
              alt="ethereum-token-workflow"
              width={392} />
              )}
        
      </div>
    </section>
    </div>
  )
}

export default HowOur