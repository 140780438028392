import React,{useEffect,useState} from "react"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"


const PortfolioSection = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

    return (
      <section className="portfoliosec pt-100 mb-0">
        <div className="gray-bg">
          <div className="container">
              <h4 className="heading-h2 text-center">
                {" "}
                <span className="heading-h3">
                  <span className="bluecolor">Our Portfolio Powers</span> more
                  than 350
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faPlus} />{" "}
                  </span>
                </span>
                Cryptocurrency Exchanges Globally{" "}
              </h4>
            <div className="quick text-center">
              { isDesktop && (
              <StaticImage src="https://coinsclone.mo.cloudinary.net/images/home-new/our-portfolio.webp" 
              alt="our portfolio"
              width={1110} />
              )}
              { isMobile && (
              <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/portfolio-mobile.webp" 
              alt="our portfolio"
              width={397} />
              )}
            </div>
          </div>
        </div>
      </section>
    )
}

export default PortfolioSection
