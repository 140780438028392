import React from 'react'

const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className='gray-bg'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="bluecolor">Process Of</span> Our Ethereum Token Development
            </h2>
            <p className="pharagraph head">Coinsclone follows some standard procedures to launch an Ethereum Token.
            </p>
          </div>
          <div className="quick text-center"> 
          <img className='mb-0 lazyload d-none d-md-block' width="1198px" height="262px" src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/ethereum-process.webp" alt="development process"/>
          </div>
          <div className="quick text-center"> 
          <img className='mb-0 lazyload d-sm-block d-md-none' width="355px" height="814px" src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/ethereum-mobile-process.webp" alt="development process"/>
          </div>
        </div>
      </div>
      </div>
    </section>
  )
}

export default DevApproach