import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'



class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="secureof bgremove pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2 mb-2"><span className="heading-h3">What are the <span className="bluecolor">Benefits of</span></span> Our Ethereum Token Development Services?</h2>
              <p class="text-center">Explore the benefits of choosing our expert team for your Ethereum token creation needs.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/eth/benefits-of-development.png"
              alt="Benefits of developing an ethereum token"
              width={571}
              height={529}
            />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Raise Funds Rapidly through ICO -</b> Create an Ethereum token to carry out the ICO development process in a more convenient manner. Hence, quickly raising funds through ICO is possible with Ethereum token development.
              </p>
              <p className="pharagraph" ><b>Secured Token Development -</b> One of the primary reasons behind developing Ethereum tokens for ICO is security. Hence, the token development and ICO launching process is safeguarded from any data breach by including high-end security protocols.
              </p>
              <p className="pharagraph" ><b>Trust Building -</b> Ethereum has created a huge trust in the Crypto society and is preferred by a massive set of users. Hence, creating tokens on the Ethereum blockchain is an excellent move and has more benefits comparatively.
              </p>
              <p className="pharagraph" ><b>No Limit to Token Creation -</b> An unlimited number of tokens can be created on the Ethereum blockchain without much errors. Token creation is used for multiple purposes and the higher the number, the higher will be its impact in the Crypto industry.
              </p>
              <p className="pharagraph mb-0" ><b>Elimination of Intermediaries -</b> Since token development involves the role of smart contracts, the elimination of intermediaries is a major plus. Hence, a considerable amount of fee is saved due to no intermediary involvement.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf