import React from 'react'



class VariousErc extends React.Component { 
  
  
  render() {
 
    return (
      <section className="icon secureof pt-100 padremove">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Various ERC</span> Standards We Work On</h2>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc20.png" alt="ERC20 Token Development" />
                ERC20 Token Development
                  </h3>
                <p className="pharagraph">Create highly popular ERC20 tokens in the best way possible with the guidance of our experts. You can make use of the utility tokens during the ICO (Initial Coin Offering) process and raise funds considerably.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc721.png" alt='ERC721 Token Development' />
                ERC721 Token Development
                </h3>
                <p className="pharagraph">Create incredible non-fungible tokens on the Ethereum blockchain using the ERC721 token standard. NFTs have high value as they are unique and cannot be exchanged for anything of equal value.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc777.png" alt='ERC777 Token Development' />
                ERC777 Token Development
                </h3>
                <p className="pharagraph">Make specially designed ERC777 tokens to resolve the limitations set by ERC20 tokens. ERC777 tokens are completely compatible with DEX and use a mechanism called hooks to make smart contracts more efficient.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc712.png" alt='ERC712 Token Development' />
                ERC712 Token Development
                </h3>
                <p className="pharagraph">Witness a more proposed user-friendly way to interact with smart contracts by developing ERC712 tokens. ERC 712 tokens support complex data types and make the Ethereum blockchain more versatile.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc223.png" alt='ERC223 Token Development' />
                ERC223 Token Development
                </h3>
                <p className="pharagraph">Develop ERC223 tokens to allow the transfer of tokens to a digital wallet more securely using a custom smart contract. These Ethereum tokens with improved capabilities come into the category of first-class value transfer assets.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc827.png" alt='ERC827 Token Development' />
                ERC827 Token Development
                </h3>
                <p className="pharagraph mb-0">Facilitate ERC827 token development aiming to solve the flaws in the design of ERC20 tokens. Additionally, these tokens are built to allow the execution of calls inside the transfers and approvals.</p>
              </div>
            </div>
          </div>
          <div className="row "> 
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc865.png" alt='ERC865 Token Development' />
                ERC865 Token Development
                </h3>
                <p className="pharagraph mb-0">Create highly functional ERC865 tokens allowing token holders to transfer multiple tokens in a single transaction. Besides, holders can also pay the transfers in tokens instead of gas to minimize costs.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc1155.png" alt='ERC1155 Token Development' />
                ERC1155 Token Development
                </h3>
                <p className="pharagraph mb-0">Involve in ERC1155 token development to create fungible tokens and non-fungible token characteristics all in a single contract. It is one of the most efficient tokens with advanced capabilities in a single measure.</p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc6551.png" alt='ERC6551 Token Development' />
                ERC6551 Token Development
                </h3>
                <p className="pharagraph mb-0">ERC6551 token development shows the evolution of token standards by enabling Token Bound Accounts (TBAs). Implementing these tokens ensures that an NFT is assigned an Ethereum account specifically.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h3 className='head3'>
                <img width="55" height="55" src="https://coinsclone.mo.cloudinary.net/images/ethereum/erc4626.png" alt='ERC4626 Token Development' />
                ERC4626 Token Development
                </h3>
                <p className="pharagraph">Design ERC4626 tokens to introduce techniques, and parameters and to provide a standard API for tokenized yield-bearing vaults. These tokens allow users to withdraw more than their initial payment over time, unlike the ERC20 token standard.</p>
              </div>
            </div>
          </div>
          <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-2 m-auto d-table">Hire our Ethereum token developers</a>
          </div>
      </section>
    )
  }
}

export default VariousErc