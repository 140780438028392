import React, { Component } from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="heading-h4"><span className="bluecolor"> Core Features</span> of our </span>
            Ethereum Token Development</h2>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Token Sale Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Token Standards
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  KYC System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Buy Tokens 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Referral Program
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Support Tickets
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Gas and Fees
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Solidity
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Smart Contracts
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Marketing
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Token Sale Dashboard
                        </h3>
                        <p className="pharagraph">Our token sale dashboard provides an array of data for investors to look into including purchased token balance, token sale stage, and purchase history.
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/token-sale-dashboard.png"
                      alt="Token Sale Dashboard"
                      width={431}
                      height={285}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Token Standards</h3>
                        <p className="pharagraph">In our Ethereum token development, we design and create tokens on different token standards based on requirements. For example, the ERC20 token standard states the guidelines for creating fungible tokens while the ERC721 token standard is for non-fungible tokens.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <img className='lazyload' width="428" height="304" src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/token-standards.webp" alt="Token Standards" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">KYC System</h3>
                        <p className="pharagraph">In any of our services, we keep prioritizing the importance of a secure system. On that note, we include the KYC process in our Ethereum Token Development Solutions where ID verification, document verification, and face verification are carried out extensively.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/kyc-system.png"
                      alt="KYC System"
                      width={328}
                      height={269}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Buy Tokens
                        </h3>
                        <p className="pharagraph">Users can deposit Crypto/Fiat in their respective wallets from their external wallets through their wallet address or by scanning a QR code. The tokens will get updated in their wallet equivalent to the amount of crypto they have deposited in their wallet.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/buy-tokens.png"
                      alt="Buy Tokens"
                      width={307}
                      height={209}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Referral Program</h3>
                        <p className="pharagraph">By using the rewards feature, your users can refer to another person and earn cash rewards instantly. So we integrated this core feature in our system that helps you increase the user count quickly and attract many users easily.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/referral-program.png"
                      alt="Referral Program"
                      width={400}
                      height={300}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Support Tickets</h3>
                        <p className="pharagraph">Users can submit their issues and queries as tickets to the admin. </p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/support-tickets.png"
                      alt="Support Tickets"
                      width={343}
                      height={338}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Gas and Fees</h3>
                        <p className="pharagraph">Each Ethereum transaction requires a computational resource to execute, each transaction requires a fee. Gas refers to the fee required to conduct a transaction on Ethereum successfully.</p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/gas-and-fees.png"
                      alt="Gas and Fees"
                      width={400}
                      height={370}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Solidity</h3>
                        <p className="pharagraph">Solidity is an object-oriented programming language created specifically by the Ethereum network team for constructing and designing smart contracts on Blockchain platforms. </p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/solidity.png"
                      alt="Solidity"
                      width={315}
                      height={241}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Smart Contracts</h3>
                        <p className="pharagraph">Smart contracts contain code that is executed in the Ethereum Blockchain. This means it is not controlled by a user and they can send transactions in the blockchain.</p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/smart-contract.png"
                      alt="Smart Contracts"
                      width={323}
                      height={321}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h3 className="heading-h4">Marketing</h3>
                        <p className="pharagraph">To make your token popular, we will assist you in reaching popular social media channels such as Facebook, Reddit, Telegram groups, Quora channels and Specialized forums.</p>
                         </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/ethereum-new/marketing.png"
                      alt="Marketing"
                      width={327}
                      height={328}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures